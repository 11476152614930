import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import promotionService from "../../services/promotion.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
const Promotion = () => {
    const [promotions, setPromotions] = useState([])
    const navigate = useNavigate();
   
      useEffect(() => {
        const getPromotion = async () => {
            try {
                const {data: dataPromotion} = await promotionService.get()
                if (dataPromotion.code === 200) {
                    setPromotions(dataPromotion.data);
                    if(dataPromotion.data.length > 0){
                        const script = document.createElement('script');
    
                        script.src = '/js/page-promotion.js';
                        script.async = true;
                    
                        document.body.appendChild(script);
                    
                        return () => {
                        document.body.removeChild(script);
                        }
                    }
                    
                }
                 
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(promotions.length <= 0){
            getPromotion()
        }
        ///////
        
      }, []);
      const handleShowDetail = (text,id,receive) => {
        MySwal.fire({
            html: text,
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: receive,
            confirmButtonText: 'รับโปร',
            cancelButtonText: 'ปิด'
        }).then(async function(result) {
            if (result.value) {
                MySwal.fire({
                    title: 'โหลด...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        MySwal.showLoading()
                    },
                });
                try {
               
                    const {data: dataReceivePro} = await promotionService.receivePromotion(id)
                    if (dataReceivePro.code === 200) {
                        MySwal.fire({
                            icon: 'success',               
                            title: <p>รับโปรเรียบร้อย</p>,
                            showConfirmButton: false,
                            timer: 1500
                          }).then(function(result){
                            MySwal.fire({
                                title: 'โหลด...',
                                allowOutsideClick: false,
                                didOpen: () => {
                                    MySwal.showLoading()
                                },
                            });
                            window.location.reload(false);
                        });
                    }else{
                        MySwal.fire({
                            icon: 'error',               
                            title: <p>เกิดข้อผิดพลาด</p>,
                            text: dataReceivePro.message,
                          })
                    }
                } catch (error) {
                    console.error(error)
                    localStorage.clear();
                    navigate('/auth/login');
                }
            }
        });
      }
    return (
        <>
        <div className="headerprocess"><i className="fal fa-gift"></i> YOUR PROMOTION</div>
        <div className="containprocess containpromotion">
            <div className="swiper primarySwiper">
                <div className="swiper-wrapper">
                {promotions.length > 0 ?
                    promotions.map((promotion, i) => {
                        
                        return (
                        <div className="swiper-slide" key={i}>
                            <img src={promotion.data.imageUrl} alt="" onClick={() => handleShowDetail(promotion.description, promotion.id, promotion.receive)} />
                        </div>
                        
                        )
                    }) : null
                }
                    
                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-pagination"></div>
            </div>
        </div>
      
        </>
    );
}
export default Promotion;